import { useContext } from 'react';
import { useQuery } from 'react-query';

import { getProfile } from 'services/ProfileService';

import { Profile } from 'model/Profile';
import { AppContext } from 'provider/AppContext';

const useProfile = () => {
  const { userAccount } = useContext(AppContext);
   
  const {
    isLoading,
    error,
    data: profile,
    refetch,
  } = useQuery<Profile, Error>('profile', () => getProfile(userAccount));

  return {
    isLoading,
    error,
    profile:
      profile ||
      ({
        name: '',
        avatar: '',
        frame: '',
        chain: '',
      } as Profile),
    refetch,
  };
};

export default useProfile;
