import { createContext, useMemo } from 'react';

import useAudio from 'hooks/useAudio';

interface AudioProviderProps {
  children: React.ReactNode;
}

export const AudioContext = createContext({
  currentSong: '',
  changingSong: false,
  playing: true,
  shuffle: false,
  toggle: () => {
    // DO SOMETHING
  },
  previous: () => {
    // DO SOMETHING
  },
  next: () => {
    // DO SOMETHING
  },
  toggleShuffle: () => {
    // DO SOMETHING
  },
});

const AudioProvider = ({ children }: AudioProviderProps) => {
  const {
    song, changingSong, playing, shuffle,
    toggle, previous, next, toggleShuffle,
  } = useAudio();

  const audioContext = useMemo(() => ({
    currentSong: song,
    changingSong,
    playing,
    shuffle,
    toggle,
    previous,
    next,
    toggleShuffle,
  }), [
    song, changingSong, playing, shuffle,
    toggle, previous, next, toggleShuffle,
  ]);

  return (
    <AudioContext.Provider value={audioContext}>
      {children}
    </AudioContext.Provider>
  );
};

export default AudioProvider;
