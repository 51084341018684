import { createGlobalStyle } from 'styled-components';

import ddc from 'assets/fonts/ddc.otf';
import open24 from 'assets/fonts/open24.ttf';
import technology from 'assets/fonts/technology.ttf';
import homenaje from 'assets/fonts/Homenaje-Regular.ttf';

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DDC Hardware';
    src: url(${ddc}) format('opentype');
  }
  @font-face {
    font-family: 'Open 24 Display St';
    src: url(${open24}) format('opentype');
    font-display: auto;
  }
  @font-face {
    font-family: 'Technology';
    src: url(${technology}) format('opentype');
    font-display: auto;
  }
  @font-face {
    font-family: 'Homenaje';
    src: url(${homenaje}) format('opentype');
    font-display: auto;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;

    @media (max-height: 895px), (max-width: 1366px) {
      font-size: 10px;
    }
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root {
    isolation: isolate;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  @media (max-width: 600px), (max-height: 630px) {
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }

  ::-webkit-scrollbar-track {
    background: #1c1c22;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ff2d7e;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ae1d55;
  }
`;

export default GlobalStyle;
