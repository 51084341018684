import { axiosInstanceBackendAPI } from 'helpers/AxiosHelper';
import { TOS } from 'model/TOS';
import { getProfile } from './ProfileService';

export const getLatestTOS = (): Promise<TOS | null> =>
  axiosInstanceBackendAPI.get('/v1/tos/latest').then((response) => response.data);

export const getLatestTOSVersion = (): Promise<string | null> =>
  axiosInstanceBackendAPI.get('/v1/tos/latest-version').then((response) => response.data);

export const tosToAccept = async (userAccount: string): Promise<TOS | null> => {
  if (!userAccount) return null;

  const profile = await getProfile(userAccount);
  if (!profile || !profile?.account) return null;

  const tos = await getLatestTOS();
  if (tos === null) return null;
  if (tos.version === profile.tos) return null;

  return tos;
};

export const acceptTOS = (version: string) =>
  axiosInstanceBackendAPI.post(
    '/v1/tos/accept',
    {
      version,
    },
    { withCredentials: true },
  );
