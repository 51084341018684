import styled from 'styled-components';

import Spinner from 'components/Spinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  loading?: boolean;
  compact?: boolean;
  children: React.ReactNode;
}

const Button = ({
  primary, loading, compact, children, ...rest
}: ButtonProps) => (
  <StyledButton
    type="button"
    primary={primary}
    disabled={loading}
    compact={compact}
    $loading={loading}
    {...rest}
  >
    {loading ? (
      <Spinner size={15} />
    ) : (
      <Children compact={compact}>
        {children}
      </Children>
    )}
  </StyledButton>
);

export default Button;

Button.defaultProps = {
  primary: false,
  loading: false,
  compact: false,
};

const StyledButton = styled.button<{
  primary: boolean | undefined,
  compact: boolean | undefined,
  $loading: boolean | undefined,
}>`
  background: ${(props) => (
    props.primary
      ? props.theme.gradients.pink
      : props.theme.gradients.slate
  )};
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => (
    props.primary
      ? props.theme.colors.pink
      : props.theme.colors.darkGrey
  )};
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  ${(props) => props.compact && `
    padding: 4px;
  `}
  ${(props) => props.$loading && `
    padding: 0 1rem;
  `}

  &:disabled {
    cursor: not-allowed;
    opacity: ${(props) => (props.$loading ? '1' : '0.8')};
    ${(props) => !props.primary && `
      background: transparent;
    `}
  }
`;

const Children = styled.div<{ compact: boolean | undefined }>`
  font-family: 'DDC Hardware', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.compact ? '5px' : '1rem')};
`;
