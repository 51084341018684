import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import video from './brawler-mask.webm';

const ScreenLoader = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Video playsInline autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </Video>
      <Text>{t('screenLoader')}</Text>
    </Layout>
  );
};

export default ScreenLoader;

const Layout = styled.div`
  background-color: #181818;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
`;

const Video = styled.video`
  width: 25rem;
`;

const Text = styled.span`
  font-family: 'DDC Hardware', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
`;
