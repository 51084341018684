import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_EN from './en/translations.json';
import TRANSLATIONS_ES from './es/translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    parseMissingKeyHandler: (key) => key.split('.').slice(-1),
  });
