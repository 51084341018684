import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import theme from 'theme/theme';

import { AppProvider } from 'provider/AppProvider';
import ErrorFallback from 'components/ErrorFallback';
import AudioProvider from 'components/AudioProvider';
import SettingsProvider from 'components/SettingsProvider';

import Router from 'Router';

const oneHourInMiliseconds = 1000 * 60 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      refetchInterval: oneHourInMiliseconds,
      staleTime: oneHourInMiliseconds,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <SettingsProvider>
            <AudioProvider>
              <Router />
            </AudioProvider>
          </SettingsProvider>
        </AppProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </QueryClientProvider>
);

export default App;
