import { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import ScreenLoader from 'components/screenLoader/ScreenLoader';
import RequireAuth from 'components/guards/RequireAuth';
import RequireTOS from 'components/guards/RequireTOS';

const SplashScreen = lazy(
  () => import(/* webpackChunkName: "splash" */ 'pages/splashScreen/SplashScreen'),
);
const Migrate = lazy(() => import(/* webpackChunkName: "migrate" */ 'pages/migrate/Migrate'));
const HomePage = lazy(() => import(/* webpackChunkName: "home" */ 'pages/home/HomePage'));
const Inventory = lazy(
  () => import(/* webpackChunkName: "inventory" */ 'pages/inventory/Inventory'),
);
const Exchange = lazy(() => import(/* webpackChunkName: "exchange" */ 'pages/exchange/Exchange'));
const News = lazy(() => import(/* webpackChunkName: "news" */ 'pages/news/News'));
const PowerScore = lazy(
  () => import(/* webpackChunkName: "powerScore" */ 'pages/powerScore/PowerScore'),
);
const Shop = lazy(() => import(/* webpackChunkName: "shop" */ 'pages/shop/Shop'));
const CraftingMachine = lazy(
  () => import(/* webpackChunkName: "craftingMachine" */ 'pages/craftingMachine/CraftingMachine'),
);
const MarketplaceSaleDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "marketplaceSaleDetail" */ 'pages/marketplaceSaleDetail/MarketplaceSaleDetail'
    ),
);
const Settings = lazy(() => import(/* webpackChunkName: "shop" */ 'pages/settings/Settings'));
const Download = lazy(() => import(/* webpackChunkName: "shop" */ 'pages/download/Download'));
const ClientAuth = lazy(() => import(/* webpackChunkName: "shop" */ 'pages/clientAuth/ClientAuth'));
const Event = lazy(() => import(/* webpackChunkName: "shop" */ 'pages/event/Event'));

const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<ScreenLoader />}>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="migrate" element={<Migrate />} />
        <Route
          path="home"
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route
          path="in-game-nfts"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <Inventory />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="wallet-nfts"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <Inventory />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="my-items"
          element={
            <RequireAuth>
              <RequireTOS>
                <Inventory />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="my-auctions"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <Inventory />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="exchange"
          element={
            <RequireAuth>
              <RequireTOS>
                <Exchange />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="news"
          element={
            <RequireAuth>
              <RequireTOS>
                <News />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="power-score"
          element={
            <RequireAuth>
              <RequireTOS>
                <PowerScore />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="store"
          element={
            <RequireAuth>
              <RequireTOS>
                <Shop />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="marketplace"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <Shop />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="auctions"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <Shop />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="event"
          element={
            <RequireAuth>
              <RequireTOS>
                <Event />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="brawl-o-seum"
          element={
            <RequireAuth onlyWaxUsersAccess>
              <RequireTOS>
                <CraftingMachine />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="marketplace/:marketContract/:saleId"
          element={
            <RequireAuth>
              <RequireTOS>
                <MarketplaceSaleDetail />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route path="game" element={<Navigate to="/download" />} />
        <Route
          path="download"
          element={
            <RequireAuth>
              <RequireTOS>
                <Download />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="client-auth"
          element={
            <RequireAuth>
              <RequireTOS>
                <ClientAuth />
              </RequireTOS>
            </RequireAuth>
          }
        />
        <Route
          path="settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
