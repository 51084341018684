import useLocalStorage from 'hooks/useLocalStorage';

const useSoundEffectsVolume = () => {
  const { value, setValue } = useLocalStorage({
    storageKey: 'sound-effects-volume',
    fallbackState: 0.5,
  });

  return {
    soundEffectsVolume: value,
    setSoundEffectsVolume: setValue,
  };
};

export default useSoundEffectsVolume;
