import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';

import theme from 'theme/theme';

import referee from 'assets/referee.png';

import Button from 'components/button/Button';

interface ErrorFallbackProps {
  resetErrorBoundary(): void;
}

const ErrorFallback = ({ resetErrorBoundary }: ErrorFallbackProps) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <img src={referee} alt="referee" />
        <Title>
          {t('errors.general')}
        </Title>
        <StyledButton primary onClick={resetErrorBoundary}>
          {t('errors.retry')}
        </StyledButton>
      </Layout>
    </ThemeProvider>
  );
};

export default ErrorFallback;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100%;
  background-color: #181818;
`;

const Title = styled.div`
  font-family: 'DDC Hardware', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  max-width: 25rem;
  color: ${(props) => props.theme.colors.white};
`;

const StyledButton = styled(Button)`
  max-width: 12rem;
`;
