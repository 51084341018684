import useLocalStorage from 'hooks/useLocalStorage';

const useShowBackgroundAnimations = () => {
  const { value, setValue } = useLocalStorage({
    storageKey: 'show-background-animations',
    fallbackState: true,
  });

  return {
    showBackgroundAnimations: value,
    setShowBackgroundAnimations: setValue,
  };
};

export default useShowBackgroundAnimations;
