import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from 'provider/AppContext';
import ScreenLoader from 'components/screenLoader/ScreenLoader';
import useProfile from 'hooks/useProfile';
import useSession from 'hooks/useSession';

const RequireAuth = ({
  checkTemp,
  children,
  onlyWaxUsersAccess,
}: {
  checkTemp?: boolean;
  onlyWaxUsersAccess?: boolean;
  children: JSX.Element;
}) => {
  const { wax, isWaxUser } = useContext(AppContext);

  const { isAuthenticated, loading: sessionLoading } = useSession();
  const { profile, isLoading: profileLoading } = useProfile();

  const currentLocation = new URL(window.location.href);
  const encodedLocation = encodeURIComponent(
    currentLocation.href.replace(currentLocation.origin, ''),
  );
  const redirectLocation = `/?return_to=${encodedLocation}`;

  if (!sessionLoading && !isAuthenticated) return <Navigate to={redirectLocation} />;
  if (checkTemp && profile?.isTemp) return <Navigate to={redirectLocation} />;

  if (profileLoading || sessionLoading) return <ScreenLoader />;

  // Validations for wax users
  if (isWaxUser) {
    if (!wax && !profileLoading) return <ScreenLoader />;
    if (!profile?.account && wax?.user === undefined) return <ScreenLoader />;

    return isWaxUser && !wax?.user ? <Navigate to={redirectLocation} /> : children;
  }

  // Validations for solana users
  if (onlyWaxUsersAccess) return <Navigate to="/home" />;

  return children;
};

export default RequireAuth;

RequireAuth.defaultProps = {
  checkTemp: false,
  onlyWaxUsersAccess: false,
};
