import { createContext, useMemo, useState } from 'react';

import useMusicVolume from 'hooks/useMusicVolume';
import useSoundEffectsVolume from 'hooks/useSoundEffectsVolume';
import useShowVideos from 'hooks/useShowVideos';

interface SettingsProviderProps {
  children: React.ReactNode;
}

export const SettingsContext = createContext({
  musicVolume: 0.5,
  soundEffectsVolume: 0.5,
  showBackgroundAnimations: true,
  isFirstLogin: false,
  setMusicVolume: (_: number) => {
    // DO SOMETHING
  },
  setSoundEffectsVolume: (_: number) => {
    // DO SOMETHING
  },
  setShowBackgroundAnimations: (_: boolean) => {
    // DO SOMETHING
  },
  setIsFirstLogin: (_: boolean) => {
    // DO SOMETHING
  },
});

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { musicVolume, setMusicVolume } = useMusicVolume();
  const { soundEffectsVolume, setSoundEffectsVolume } = useSoundEffectsVolume();
  const { showBackgroundAnimations, setShowBackgroundAnimations } = useShowVideos();
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const settingsContext = useMemo(
    () => ({
      musicVolume,
      soundEffectsVolume,
      showBackgroundAnimations,
      isFirstLogin,
      setMusicVolume,
      setSoundEffectsVolume,
      setShowBackgroundAnimations,
      setIsFirstLogin,
    }),
    [
      musicVolume,
      soundEffectsVolume,
      showBackgroundAnimations,
      isFirstLogin,
      setMusicVolume,
      setSoundEffectsVolume,
      setShowBackgroundAnimations,
      setIsFirstLogin,
    ],
  );

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
