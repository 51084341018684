import { useEffect, useState } from 'react';

interface LocalStorageProps<T> {
  storageKey: string;
  fallbackState: T;
}

// eslint-disable-next-line comma-spacing
const useLocalStorage = <T,>({ storageKey, fallbackState }: LocalStorageProps<T>) => {
  const [value, setValue] = useState<T>(fallbackState);

  useEffect(() => {
    const item = localStorage.getItem(storageKey);

    if (item === null) {
      setValue(fallbackState);
    } else {
      setValue(JSON.parse(item));
    }
  }, [storageKey]);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value]);

  return { value, setValue };
};

export default useLocalStorage;
