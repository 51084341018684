import ReactDOM, { render, unmountComponentAtNode } from 'react-dom';

import { useEffect } from 'react';

import styled, { keyframes, ThemeProvider } from 'styled-components';

import theme from 'theme/theme';

import closeIcon from 'assets/icons/close.svg';

import { handleKeyDown } from 'helpers/A11y';

import referee from './referee.png';

interface NotificationProps {
  message: string;
  textAction?: string;
  onClickAction?(): void;
}

export const triggerNotification = ({ message, textAction, onClickAction }: NotificationProps) => {
  let messageToShow = message;

  if (typeof message === 'string') {
    messageToShow = message.replace('assertion failure with message: ', '');

    const MAINTENANCE_MODE_MESSAGE =
      'Game in maintenance mode, please try again later or check site for latest updates';

    // We don't want to show an overdrawn balance for bcbrawlers account
    const overdrawnBalanceRegExp = /Can not debit .* BRWL. Only .* BRWL available for bcbrawlers/;

    if (overdrawnBalanceRegExp.test(messageToShow) || messageToShow === 'MaintenanceMode') {
      messageToShow = MAINTENANCE_MODE_MESSAGE;
    }
  } else if (!messageToShow) messageToShow = 'Network error, please try again later';

  render(
    ReactDOM.createPortal(
      <ThemeProvider theme={theme}>
        <Notification
          message={messageToShow}
          textAction={textAction}
          onClickAction={onClickAction}
        />
      </ThemeProvider>,
      document.body,
    ),
    document.getElementById('notification'),
  );
};

const Notification = ({ message, textAction, onClickAction }: NotificationProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => close(), 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, [message]);

  const close = () => {
    const element = document.getElementById('notification');
    if (element !== null) {
      unmountComponentAtNode(element);
    }
  };

  const runAction = () => {
    if (onClickAction !== undefined) onClickAction();

    close();
  };

  return (
    <Position>
      <Layout>
        <Img src={referee} alt="referee" />
        <div>
          <Message>{message}</Message>
          {textAction !== undefined && onClickAction !== undefined && (
            <Action
              role="button"
              tabIndex={0}
              onClick={runAction}
              onKeyDown={(event) => handleKeyDown(event, runAction)}
            >
              {textAction}
            </Action>
          )}
        </div>
        <Close
          role="button"
          tabIndex={0}
          onClick={close}
          onKeyDown={(event) => handleKeyDown(event, close)}
        >
          <img src={closeIcon} alt="Close" />
        </Close>
      </Layout>
    </Position>
  );
};

export default Notification;

Notification.defaultProps = {
  textAction: undefined,
  onClickAction: undefined,
};

const Position = styled.div`
  position: absolute;
  top: 1rem;
  left: 2rem;
  min-width: 30%;
  max-width: 40%;

  z-index: 10;

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-width: 40%;
    max-width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    min-width: 60%;
    max-width: 70%;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    min-width: 75%;
    max-width: 80%;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.gradients.slate};
  color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  border: 2px solid #282b30;
  animation: ${slideIn} 1s ease-out;
`;

const Img = styled.img`
  height: 90px;
  transform: translateX(-15px) scale(1.1);

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 70px;
  }
`;

const Message = styled.div`
  font-family: 'DDC Hardware', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  align-self: center;
  padding: 0.3rem 3.2rem 0.2rem 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 1.3rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 1rem;
  }
`;

const Action = styled(Message)`
  color: ${(props) => props.theme.colors.pink};
  cursor: pointer;
  width: fit-content;
`;

const Close = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: ${(props) => props.theme.colors.pink};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  padding: 5px;
  border-radius: 1rem;
`;
