import * as waxjs from '@waxio/waxjs/dist';
import { Action } from 'eosjs/dist/eosjs-serialize';

import { CONTRACT_NAME } from 'constants/Constants';

import { User } from 'model/User';

export const DEFAULT_WAX_CHAIN_ID = '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4';

export const getRpcEndpoints = () => {
  if (!process.env.REACT_APP_WAX_CHAIN_URL) {
    return [{
      protocol: 'https',
      host: 'api.waxnet.io',
      port: 443,
      endpoint: 'https://api.waxnet.io:443',
    }];
  }

  const chainURL = new URL(process.env.REACT_APP_WAX_CHAIN_URL);
  const protocol = chainURL.protocol.replace(/:$/, '');
  const host = chainURL.hostname;
  const port = Number(chainURL.port) || (chainURL.protocol === 'http' ? 80 : 443);

  return [
    {
      protocol,
      host,
      port,
      endpoint: `${protocol}://${host}:${port}`,
    },
  ];
};

export const getAll = async ({ table } : { table: string }) => getRows({ table });

export const getAllByScope = async (
  { table, scope, contract = null } : { table: string, scope: string, contract?: string | null},
) => getRows({ table, scope, contract });

export const getOneByIndex = async (
  { table, index } : { table: string, index: string },
) => {
  const { rows } = await getTableRows({
    table,
    lowerBound: index,
    upperBound: index,
  });

  if (rows.length === 0) return null;

  return rows[0];
};

export const getAllBySecondaryIndex = async (
  { table, index } : { table: string, index: string },
) => {
  const { rows } = await getTableRows({
    table,
    lowerBound: index,
    upperBound: index,
    options: {
      index_position: 'secondary',
      key_type: 'i64',
    },
  });

  return rows;
};

export const getOneByMaxKey = async ({ table } : { table: string }) => {
  const { rows } = await getTableRows({ table, options: { limit: 1, reverse: true } });

  if (rows.length === 0) return null;

  return rows[0];
};

const getRows = async ({
  table,
  scope = null,
  contract = null,
  lowerBound = null,
  upperBound = null,
  partialResult = [],
  options = {},
}: {
  table: string;
  scope?: string | null;
  contract?: string | null;
  lowerBound?: string | null;
  upperBound?: string | null;
  partialResult?: unknown[];
  options?: Record<string, unknown>
}): Promise<unknown> => {
  const response = await getTableRows({
    table, scope, contract, lowerBound, upperBound, options,
  });

  if (response.rows) {
    const cache = [...partialResult, ...response.rows];

    if (!response.more) return cache;

    return getRows({
      table,
      scope,
      contract,
      lowerBound: response.next_key,
      upperBound,
      partialResult: cache,
      options,
    });
  }

  return partialResult;
};

export const getTableRows = async ({
  table,
  scope = null,
  contract = null,
  lowerBound = null,
  upperBound = null,
  options = {},
}: {
  table: string;
  scope?: string | null;
  contract?: string | null;
  lowerBound?: string | null;
  upperBound?: string | null;
  options?: Record<string, unknown>
}) => {
  const defaultWaxChainUrl = 'https://chain.wax.io';
  const waxChainUrl = process.env.REACT_APP_WAX_CHAIN_URL || defaultWaxChainUrl;

  const response = await fetch(
    `${waxChainUrl}/v1/chain/get_table_rows`,
    {
      body: JSON.stringify({
        json: true,
        code: contract || CONTRACT_NAME,
        scope: scope || contract || CONTRACT_NAME,
        table,
        lower_bound: lowerBound,
        upper_bound: upperBound,
        limit: 1000,
        ...options,
      }),
      method: 'POST',
    },
  ).then((res) => res.json());

  return response;
};

export const signTransaction = (
  wax: waxjs.WaxJS,
  actions: Action[],
) => wax.api.transact(
  { actions },
  { expireSeconds: 120, blocksBehind: 3 },
).then((value) => new Promise((resolve) => {
  setTimeout(() => { // Wait 2.25 seconds for block time
    resolve(value);
  }, 2250);
}));

export const signUalDummyTransaction = (
  activeUser: User,
  actions: unknown,
) => activeUser.signTransaction(
  { actions },
  { expireSeconds: 120, blocksBehind: 3, broadcast: false },
);
