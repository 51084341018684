import useLocalStorage from 'hooks/useLocalStorage';

const useMusicVolume = () => {
  const { value, setValue } = useLocalStorage({
    storageKey: 'music-volume',
    fallbackState: 0.5,
  });

  return {
    musicVolume: value,
    setMusicVolume: setValue,
  };
};

export default useMusicVolume;
