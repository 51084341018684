import { useState, useEffect, useContext } from 'react';

import { SettingsContext } from 'components/SettingsProvider';

const songs = [
  'The March of the Scotty Gang',
  'Ode to Ennio',
  'Funk Flexin\' on U',
  'The March of the Patriots',
  'The Champ is Here',
  'The Cream Always Rises to the Top',
  '724 247',
  'A Great Figure for a Figure Four',
  'On Balance, Off Balance, I\'m Better Than You',
  'Colt\'s 45',
  'Rockin\' Lowkey',
  'Johnny\'s Fingers are Butter',
  'Keep Your Wide Eyes Peeled',
  'Tables, Ladders, and Flairs',
  'Barry\'s Bashing Bonanza',
  'One of One Won',
  'Out for Leather',
  'What\'s It Gon Be?',
  'Now or Never',
  'Lockeroom Loungin\'',
  'Squared Circlin\' the Drain',
  'Whack Track Attack',
  'Whale Huntah!',
  'Heat Check',
];

const getRandomSong = () : string => songs[Math.floor(Math.random() * songs.length)];

const useAudio = () => {
  const [playing, setPlaying] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [song, setSong] = useState(getRandomSong());
  const [changingSong, setChangingSong] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const { musicVolume } = useContext(SettingsContext);

  useEffect(() => {
    setChangingSong(true);

    if (audio !== undefined) {
      audio.pause();
    }

    const songName = song.toLowerCase()
      .replaceAll(' ', '-')
      .replace(/[^a-z0-9-]/g, '');

    import(`assets/music/${songName}.mp3`).then((music) => {
      setAudio(new Audio(music.default));
    });
  }, [song]);

  useEffect(() => {
    if (audio === undefined) return;

    audio.volume = musicVolume;
    audio.onended = next;

    setChangingSong(false);

    if (!playing && !isFirstLoading) {
      setPlaying(true);
    }

    if (isFirstLoading) {
      setIsFirstLoading(false);
    }
  }, [audio]);

  useEffect(() => {
    if (audio === undefined) return;

    audio.volume = musicVolume;
  }, [musicVolume]);

  useEffect(() => {
    if (audio === undefined) return;

    audio.onended = next;
  }, [shuffle]);

  useEffect(() => {
    if (audio === undefined) return;

    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing, audio]);

  const toggle = () => setPlaying((prev) => !prev);

  const toggleShuffle = () => setShuffle((prev) => !prev);

  const selectRandomSong = () => {
    const remainingSongs = songs.filter((s) => s !== song);
    const songNumber = Math.floor(Math.random() * remainingSongs.length);

    setSong(remainingSongs[songNumber]);
  };

  const previous = () => {
    if (shuffle) {
      selectRandomSong();
      return;
    }

    const idx = songs.indexOf(song);

    let prevIdx;
    if (idx === 0) {
      prevIdx = songs.length - 1;
    } else {
      prevIdx = idx - 1;
    }

    setSong(songs[prevIdx]);
  };

  const next = () => {
    if (shuffle) {
      selectRandomSong();
      return;
    }

    const idx = songs.indexOf(song);

    let nextIdx;
    if (idx === songs.length - 1) {
      nextIdx = 0;
    } else {
      nextIdx = idx + 1;
    }

    setSong(songs[nextIdx]);
  };

  return {
    song, changingSong, playing, shuffle, toggle, previous, next, toggleShuffle,
  };
};

export default useAudio;
