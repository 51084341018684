import axios from 'axios';

import { triggerNotification } from 'components/notification/Notification';

export const axiosInstanceBackendAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

export const axiosInstanceMarketplaceAPI = axios.create({
  baseURL: process.env.REACT_APP_MARKETPLACE_API_URL,
});

axiosInstanceBackendAPI.interceptors.response.use((response) => response, (error) => {
  if (error.response?.data?.message === 'MaintenanceMode') {
    triggerNotification({
      message: error.response.data.message,
    });
  }

  return Promise.reject(error);
});
