export const COLLECTION_NAME = 'bcbrawlers';
export const CONTRACT_NAME = 'bcbrawlers';
export const BE_CONTRACT_NAME = 'bcbrawlersbe';
export const TOKEN = 'BRWL';
export const TOKEN_CONTRACT = 'brawlertoken';
export const WAX_CONTRACT = 'eosio.token';
export const WAX_TOKEN = 'WAX';
export const WUFFI_CONTRACT = 'wuffi';
export const WUFFI_TOKEN = 'WUF';
export const WUFFI_PRECISION = Number(process.env.REACT_APP_WUFFI_PRECISION || 4);
