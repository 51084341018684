import React, { useEffect, useMemo, useState } from 'react';
import { axiosInstanceBackendAPI } from 'helpers/AxiosHelper';

import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { BaseMessageSignerWalletAdapter, WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import { AppContext, initWaxJs } from './AppContext';

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [wax, setWax] = useState(initWaxJs);
  const [userAccount, setUserAccount] = useState<string | null>(null);

  const [isWaxUser, setIsWaxUser] = useState<boolean | null>(null);

  const [phantomProvider, setPhantomProvider] = useState<PhantomWalletAdapter | null>(null);
  const [solflareProvider, setSolflareProvider] = useState<SolflareWalletAdapter | null>(null);
  const [solanaAdapterUsed, setSolanaAdapterUsed] =
    useState<BaseMessageSignerWalletAdapter | null>(null);

  let network: WalletAdapterNetwork;

  if (process.env.NODE_ENV === 'development') {
    network = WalletAdapterNetwork.Devnet;
  } else {
    network = WalletAdapterNetwork.Mainnet;
  }

  useEffect(() => {
    setPhantomProvider(new PhantomWalletAdapter());
    setSolflareProvider(new SolflareWalletAdapter());
  }, [network]);

  useEffect(() => {
    if (!wax?.user) {
      axiosInstanceBackendAPI
        .get('/session', { withCredentials: true })
        .then((response) => {
          setWax(initWaxJs(response.data?.accountName, response.data?.publicKeys));
          setUserAccount(response.data.accountName);
          setIsWaxUser(true);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    } else {
      setWax(initWaxJs());
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      wax,
      phantomProvider,
      solflareProvider,
      solanaAdapterUsed,
      isWaxUser,
      userAccount,
      setSolanaAdapterUsed,
      setIsWaxUser,
      setUserAccount,
    }),
    [
      wax,
      phantomProvider,
      solflareProvider,
      isWaxUser,
      solanaAdapterUsed,
      userAccount,
      setSolanaAdapterUsed,
      setIsWaxUser,
      setUserAccount,
    ],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
