export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLSpanElement>,
  callback: () => void,
) => {
  const key = event.key || event.keyCode; // Use of deprecated keyCode for browser support

  if (key === 'Enter' || key === ' ') {
    callback();
  }
};

export const handleSelfBlur = (
  event: React.FocusEvent<HTMLDivElement>,
  callback: () => void,
) => {
  const lostSelfFocus = !event.currentTarget.contains(event.relatedTarget);

  if (lostSelfFocus) {
    callback();
  }
};
