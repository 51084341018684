import { Navigate } from 'react-router-dom';

import ScreenLoader from 'components/screenLoader/ScreenLoader';

import useTOS from 'hooks/useTOS';

const RequireTOS = ({ children }: { children: JSX.Element }) => {
  const { isLoading, tos } = useTOS();

  if (isLoading) return <ScreenLoader />;

  if (tos !== null) return <Navigate to="/home" />;

  return children;
};

export default RequireTOS;
