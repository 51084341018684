import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

interface SpinnerProps {
  size?: number;
  center?: boolean;
}

const Spinner = ({ size, center = false }: SpinnerProps) => (
  <StyledSpinner center={center}>
    <ClipLoader size={size} />
  </StyledSpinner>
);

export default Spinner;

Spinner.defaultProps = {
  size: 30,
  center: false,
};

const StyledSpinner = styled.div<{ center: boolean }>`
  padding-top: 0.6rem;

  & span {
    border-color: ${(props) => props.theme.colors.white};
    border-bottom-color: transparent;
  }

  ${(props) => props.center && `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
