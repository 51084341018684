const theme = {
  colors: {
    white: '#ffffff',
    black: '#222222',
    secondaryBlack: '#111111',
    darkGrey: '#434343',
    lightGrey: '#b2b2b2',
    pink: '#ff2d7e',
    blue: '#4766d6',
    gold: '#e7b400',
    lightBlue: '#038fe4',
    rarities: {
      artifact: '#e6cc80',
      common: '#8c8b9b',
      uncommon: '#138613',
      rare: '#0070dd',
      epic: '#a335ee',
      legendary: '#ff8000',
      unknown: '#434343',
    },
  },
  gradients: {
    slate: 'linear-gradient(#30323c, #18191e)',
    modal: 'linear-gradient(#30323c, #25272f)',
    pink: 'linear-gradient(#ff2d7e, #d5006a)',
    blue: 'linear-gradient(#4766d6, #3650a2)',
    white: 'linear-gradient(#ffffff, #cecece)',
    silver: 'linear-gradient(#e1e1e1, #686868)',
    silverInvert: 'linear-gradient(360deg, #e1e1e1, #686868)',
    gold: 'linear-gradient(#FFC42D, #D58D00)',
  },
  breakpoints: {
    mobile: '(max-width: 600px), (max-height: 630px)',
    tablet: '(max-width: 930px), (max-height: 630px)',
    desktop: '(max-width: 1366px), (max-height: 895px)',
  },
  machine: {
    colors: {
      darkGrey: 'rgba(0, 0, 0, 0.85)',
      lightGrey: '#6f7474',
      mediumGrey: '#8a8a8a',
      gold: '#FFB421',
      green: '#6aff36',
      grey: 'rgba(49, 49, 49, 0.75)',
      pink: '#FF4394',
      red: 'red',
      yellow: '#FFD600',
      white: '#ffffff',
    },
    gradients: {
      gold: 'linear-gradient(#FFC42D, #D58D00)',
      modal: 'linear-gradient(#30323c, #25272f)',
      pink: 'linear-gradient(#ff2d7e, #d5006a)',
      silver: 'linear-gradient(#e1e1e1, #686868)',
      slate: 'linear-gradient(#30323c, #18191e)',
      slateBCB: 'linear-gradient(180deg, #6C7197 0%, #43444A 100%)',
    },
    breakpoints: {
      mobile: '(max-width: 600px), (max-height: 630px)',
      tablet: '(max-width: 930px), (max-height: 630px)',
      desktop: '(max-width: 1366px), (max-height: 895px)',
    },
  },
};

export default theme;
