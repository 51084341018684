import { useQuery } from 'react-query';

import { isActiveSession } from 'services/LoginService';

const useSession = () => {
  const {
    isLoading,
    data: isAuth,
  } = useQuery<boolean, Error>('session', () => isActiveSession());

  return {
    isAuthenticated: isAuth,
    loading: isLoading,
  };
};

export default useSession;
